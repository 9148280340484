import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { createStructuredSelector } from 'reselect';
import MuiContainer from '@material-ui/core/Container';
import isEmpty from 'lodash/isEmpty';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useHistory, useParams } from 'react-router-dom';
import {
  getDeliveryRoute,
  getTruckAssignment,
  resetTruckAssignment,
  setOrder,
  setTruckAssignment,
  submitTruckAssignment,
  updateTruckAssignment,
} from './actions';
import {
  makeSelectTotals,
  selectDisabled,
  selectLoading,
  selectOrderItems,
  selectRows,
  selectSaved,
  selectTruckAssignment,
} from './selectors';
import TruckAssignmentForm from '../../components/TruckAssignmentForm';
import CreateTruckAssignmentMessage from './CreateTruckAssignmentMessage';
import TruckAssignmentConfirmDialog from './TruckAssignmentConfirmDialog';
import TruckAssignmentOrderTable from '../../components/TruckAssignmentOrderTable';
import reducer from './reducer';
import saga from './saga';
import { getProducts } from '../App/actions';
import TruckAssignmentQueueCard from '../../components/TruckAssignmentQueueCard';
import { makeSelectOrderItems } from '../App/selectors';
import TruckAssignmentConfirmButtons from '../TruckAssignmentConfirmButtons';
import OrderOptions from '../../components/OrderOptions';
import {
  selectGroupedProducts,
  selectTruckAssignmentProductGroups,
} from '../TruckAssignment/selectors';

const stateSelector = createStructuredSelector({
  rows: selectRows,
  orderItems: makeSelectOrderItems(selectOrderItems),
  groups: selectTruckAssignmentProductGroups,
  totals: makeSelectTotals(selectRows),
  groupedProducts: selectGroupedProducts,
  truckAssignment: selectTruckAssignment,
  saved: selectSaved,
  loading: selectLoading,
  disabled: selectDisabled,
});

const key = 'createOrUpdateTruckAssignment';

export default function CreateOrUpdateTruckAssignment() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const { truckAssignmentId } = useParams();
  const history = useHistory();
  const printRef = useRef();
  const dispatch = useDispatch();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const {
    rows,
    orderItems,
    totals,
    groups,
    groupedProducts,
    truckAssignment,
    saved,
    loading,
    disabled,
  } = useSelector(stateSelector);

  useEffect(() => {
    if (isEmpty(truckAssignmentId) && isEmpty(groupedProducts)) {
      dispatch(getProducts());
    }
  }, [truckAssignmentId, groupedProducts]);

  useEffect(() => {
    if (truckAssignmentId) {
      dispatch(getTruckAssignment(truckAssignmentId));
    }
    return () => {
      dispatch(resetTruckAssignment());
    };
  }, [truckAssignmentId]);

  useEffect(() => {
    if (!truckAssignmentId && truckAssignment.deliveryRouteId) {
      dispatch(getDeliveryRoute());
    }
  }, [dispatch, truckAssignmentId, truckAssignment.deliveryRouteId]);

  return (
    <MuiContainer>
      <ValidatorForm onSubmit={() => setOpenConfirmDialog(true)} noValidate>
        <TruckAssignmentForm
          loading={loading}
          disabled={disabled}
          printRef={printRef}
          truckAssignment={truckAssignment}
          onChange={param => e =>
            dispatch(setTruckAssignment(param, e.target.value))}
          onDateTimeChange={moment =>
            dispatch(setTruckAssignment('departure', moment))
          }
          alert={
            saved && (
              <CreateTruckAssignmentMessage
                sequenceNumber={truckAssignment.sequenceNumber}
              />
            )
          }
          rightTopMenu={
            <OrderOptions
              ref={printRef}
              printable={saved}
              showPrintOption={saved}
              showUpdateButton={saved}
              printTooltipTitle="พิมพ์บัตรคิว"
              onUpdateClick={() => dispatch(updateTruckAssignment())}
            />
          }
        />
        <TruckAssignmentOrderTable
          rows={rows}
          totals={totals}
          groups={groups}
          saved={saved}
          loading={loading}
          disabled={disabled}
          groupedProducts={groupedProducts}
          onChange={(idx, productId, quantity, weight, type) =>
            dispatch(dispatch(setOrder(idx, productId, quantity, weight, type)))
          }
          rightTopMenu={
            <OrderOptions
              ref={printRef}
              printable={saved}
              showPrintOption={saved}
              showUpdateButton={saved}
              printTooltipTitle="พิมพ์บัตรคิว"
              onUpdateClick={() => dispatch(updateTruckAssignment())}
            />
          }
          footer={
            <TruckAssignmentConfirmButtons
              saved={saved}
              onNew={() => {
                if (truckAssignmentId) {
                  dispatch(resetTruckAssignment());
                  history.push('/createOrUpdateTruckAssignment');
                } else {
                  dispatch(resetTruckAssignment());
                }
              }}
              onReset={() => {
                if (truckAssignmentId) {
                  dispatch(getTruckAssignment(truckAssignmentId));
                } else {
                  dispatch(resetTruckAssignment());
                }
              }}
            />
          }
        />
      </ValidatorForm>
      <TruckAssignmentConfirmDialog
        open={openConfirmDialog}
        onAgreeClick={() => {
          setOpenConfirmDialog(false);
          dispatch(submitTruckAssignment());
        }}
        onDisagreeClick={() => setOpenConfirmDialog(false)}
      />
      <TruckAssignmentQueueCard
        ref={printRef}
        orderItems={orderItems}
        truckAssignment={truckAssignment}
      />
    </MuiContainer>
  );
}
